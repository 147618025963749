export const showPopup = (url: string, title: string, width: number, height: number, onClose?: () => void) => {
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;

  const popup = window.open(url, title, `width=${width},height=${height},left=${left},top=${top}`);

  if (!popup) return;

  if (onClose) {
    popup.onbeforeunload = () => onClose();
  }
};
