import React from "react";
import styled from "@emotion/styled";
import CancelIcon from "@mui/icons-material/Cancel";
import useWebservice from "@livepix/components/hooks/useWebservice";
import { Connection, Reward, User } from "@livepix/sdk-js/types/core";
import useEndpoint from "@livepix/components/hooks/useEndpoint";
import ProfileButton from "@livepix/components/profile/ProfileButton";
import RewardDataModal from "components/reward/RewardDataModal";
import { showPopup } from "helpers/popup";

import { Button, Card, LinearProgress } from "@mui/material";

const Description = styled.p`
  margin: 0;
  text-align: center;
`;

const RewardsContainer = styled.div`
  width: 100%;
  margin: 20px 0 20px;
  max-height: 300px;
  overflow-x: hidden;

  @media (max-width: 800px) {
    max-height: 100%;
  }
`;

const RewardContainer = styled(Card)`
  margin-bottom: 10px;
  background: #f9f9f9;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const RewardContent = styled.div`
  padding: 14px;
`;

const RewardActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  border-top: 1px solid #e6e6e6;

  .MuiLinearProgress-root {
    width: 100%;
  }

  button {
    margin-left: 10px;

    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const RewardType = styled.p`
  margin: 0;
  text-transform: uppercase;
  font-size: 16px;
  color: #666;
`;

const RewardTitle = styled.p`
  margin: 0;
  font-weight: bold;
`;

const RewardStatus = styled.p`
  margin: 0;

  span {
    line-height: 24px;
    font-size: 14px;
    text-transform: uppercase;
  }
`;

const typeLabels: Record<string, string> = {
  "discord-role": "Cargo no Discord",
  "telegram-channel": "Grupo no Telegram",
  "campaign-coupon": "Código patrocinado",
  image: "Imagem",
  video: "Vídeo",
};

type Props = {
  user?: User;
  color?: string;
  trigger: string;
  planId?: string;
  amount: number;
  rewards: Reward[];
  onContinue: (data: Record<string, string>) => void;
  onResize: () => void;
};

export default function SetupRewards({ user, color, trigger, planId, amount, rewards, onContinue, onResize }: Props) {
  const endpoint = useEndpoint();
  const webservice = useWebservice();

  const [ignored, setIgnored] = React.useState<string[]>([]);

  const [data, setData] = React.useState<Record<string, string>>({});

  const [showDataModal, setShowDataModal] = React.useState<boolean>(false);

  const { data: connections, error: connectionsError, mutate } = webservice.load<Connection[]>("/connections");

  const eligible = React.useMemo(() => {
    if (!Array.isArray(rewards)) return [];

    const eligible = rewards.filter((reward) => {
      if (reward.granted) {
        return false;
      }

      if (!reward.triggers.includes(trigger)) {
        return false;
      }

      if (reward.grantLimit > 0 && reward.grantLimit <= reward.grantCount) {
        return false;
      }

      if (trigger === "subscription" && reward.plans.length > 0) {
        return planId && reward.plans.includes(planId);
      }

      return reward.amount <= amount;
    });

    const common = rewards.filter((reward) => !reward.unique);
    const unique = rewards.filter((reward) => reward.unique);
    const highestUnique = unique.reduce<Reward | undefined>((highest, reward) => {
      if (!highest || reward.amount > highest.amount) {
        return reward;
      }

      return highest;
    }, undefined);

    return [...common, highestUnique].filter(Boolean) as Reward[];
  }, [trigger, amount, rewards]);

  React.useEffect(() => {
    if (eligible.length === 0) {
      const timeout = setTimeout(() => onContinue({}), 100);
      return () => clearTimeout(timeout);
    }
  }, [eligible]);

  React.useEffect(onResize, [connections, connectionsError, eligible, ignored]);

  const isConfigured = (reward: Reward): boolean => {
    if (reward.type === "image" || reward.type === "video" || reward.type === "campaign-coupon") return true;

    if (ignored.includes(reward.id)) return true;

    if (reward.type === "telegram-channel") return connections !== undefined;

    if (reward.type === "physical-product") {
      return Boolean(
        data.name &&
          data.email &&
          data.phone &&
          data.postCode &&
          data.street &&
          data.streetNumber &&
          data.neighborhood &&
          data.city &&
          data.state,
      );
    }

    if (reward.type === "digital-product" || reward.type === "service") {
      return Boolean(data.name && data.email && data.phone);
    }

    return connections !== undefined && connections.some((c) => c.provider === "discord");
  };

  const ignore = (reward: Reward) => {
    setIgnored((current) => [...current, reward.id]);
  };

  const connectDiscord = () => {
    const discordUrl = webservice.buildUrl(`/oauth/discord/login?redirect=${endpoint.authUrl("/close")}`);

    const url = user?.username ? discordUrl : `${endpoint.authUrl("/signup")}?redirect=${discordUrl}`;

    showPopup(url, "Conectar Discord", 540, 840, () => mutate());
  };

  const login = () => {
    showPopup(`${endpoint.authUrl("/signup")}?redirect=/close`, "Faça login", 540, 840, () => mutate());
  };

  const connectedDiscord: Connection | undefined = React.useMemo(() => {
    if (connections) {
      return connections.find((c) => c.provider === "discord");
    }
  }, [connections]);

  if (eligible.length === 0) return null;

  return (
    <>
      {showDataModal && (
        <RewardDataModal
          types={eligible.map((reward) => reward.type)}
          onClose={(data) => {
            setData(data);
            setShowDataModal(false);
          }}
        />
      )}
      <Description>Você receberá estas recompensas:</Description>
      <RewardsContainer>
        {eligible.map((reward) => (
          <RewardContainer elevation={0} key={reward.id}>
            <RewardContent>
              <RewardType>{typeLabels[reward.type]}</RewardType>
              <RewardTitle>{reward.title}</RewardTitle>
            </RewardContent>
            <RewardActions>
              {connections || connectionsError ? (
                <>
                  {isConfigured(reward) ? (
                    <RewardStatus>
                      {ignored.includes(reward.id) ? (
                        <span>Recompensa ignorada</span>
                      ) : (
                        <>
                          {reward.type === "discord-role" && (
                            <span>
                              Conectado a <b>{connectedDiscord?.providerName}</b>
                            </span>
                          )}
                          {reward.type === "telegram-channel" && <span>O convite será enviado por e-mail</span>}
                          {reward.type === "campaign-coupon" && <span>O código será exibido após o pagamento</span>}
                          {reward.type === "image" && <span>A imagem será liberada após o pagamento.</span>}
                          {reward.type === "video" && <span>O vídeo será liberado após o pagamento.</span>}
                          {reward.type === "service" && (
                            <span>O organizador da vaquinha entrará em contato para entregar a recompensa.</span>
                          )}
                          {reward.type === "digital-product" && (
                            <span>O organizador da vaquinha entrará em contato para entregar a recompensa.</span>
                          )}
                          {reward.type === "physical-product" && (
                            <span>O produto será enviado pelo organizador da vaquinha.</span>
                          )}
                        </>
                      )}
                    </RewardStatus>
                  ) : (
                    <>
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        startIcon={<CancelIcon />}
                        onClick={() => ignore(reward)}
                      >
                        Ignorar
                      </Button>
                      {reward.type === "discord-role" && (
                        <Button size="small" variant="outlined" color="secondary" onClick={() => connectDiscord()}>
                          Conectar Discord
                        </Button>
                      )}
                      {reward.type === "telegram-channel" && (
                        <Button size="small" variant="outlined" color="secondary" onClick={() => login()}>
                          Cadastre-se
                        </Button>
                      )}
                      {reward.type === "service" && (
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => setShowDataModal(true)}
                        >
                          Informar dados
                        </Button>
                      )}
                      {reward.type === "digital-product" && (
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => setShowDataModal(true)}
                        >
                          Informar dados
                        </Button>
                      )}
                      {reward.type === "physical-product" && (
                        <Button
                          size="small"
                          variant="outlined"
                          color="secondary"
                          onClick={() => setShowDataModal(true)}
                        >
                          Informar endereço
                        </Button>
                      )}
                    </>
                  )}
                </>
              ) : (
                <LinearProgress color="primary" />
              )}
            </RewardActions>
          </RewardContainer>
        ))}
      </RewardsContainer>
      <ProfileButton
        disabled={!eligible.every((r) => isConfigured(r))}
        customcolor={color || "primary"}
        onClick={() => onContinue(data)}
      >
        Continuar
      </ProfileButton>
    </>
  );
}
