import React from "react";
import axios from "axios";
import styled from "@emotion/styled";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Modal, TextField, Typography } from "@mui/material";
import FieldContainer from "@livepix/components/form/FieldContainer";
import * as validation from "@livepix/sdk-js/validation";
import useAlerts from "@livepix/components/hooks/useAlerts";
import MaskedTextField from "@livepix/components/form/MaskedTextField";
import { isEmailValid, isNameValid, isPhoneValid } from "@livepix/sdk-js/validation";
import useUser from "@livepix/components/hooks/useUser";

const ModalCard = styled.div`
  max-width: 500px;
  max-height: 90vh;
  margin: 50px auto 0;
  padding: 20px;
  border-radius: 5px;
  background: white;
  overflow-y: auto;
`;

const Title = styled(Typography)`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
`;

const Subtitle = styled(Typography)`
  margin-bottom: 20px;
  font-size: 18px;
  color: #666;
`;

const Legend = styled(Typography)`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
`;

type Props = {
  types: string[];
  onClose: (data: Record<string, string>) => void;
};

export default function RewardDataModal({ types, onClose }: Props) {
  const alerts = useAlerts();

  const { user } = useUser();

  const [step, setStep] = React.useState<"identity" | "address">("identity");

  const [loading, setLoading] = React.useState<boolean>(false);

  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");

  const [postCode, setPostCode] = React.useState<string>("");
  const [street, setStreet] = React.useState<string>("");
  const [streetNumber, setStreetNumber] = React.useState<string>("");
  const [complement, setComplement] = React.useState<string>("");
  const [neighborhood, setNeighborhood] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");

  const [addressLoaded, setAddressLoaded] = React.useState<boolean>(false);
  const [neighborhoodFound, setNeighborhoodFound] = React.useState<boolean>(false);
  const [streetFound, setStreetFound] = React.useState<boolean>(false);

  const [termsAccepted, setTermsAccepted] = React.useState<boolean>(false);

  const loadAddress = () => {
    if (!validation.isPostCodeValid(postCode)) return;

    setLoading(true);

    axios
      .get<{
        erro?: boolean;
        logradouro: string;
        bairro: string;
        localidade: string;
        uf: string;
      }>(`https://viacep.com.br/ws/${postCode}/json`)
      .then(({ data }) => {
        if (data.erro) {
          alerts.error("CEP não encontrado.");
          return;
        }

        setStreet(data.logradouro || "");
        setStreetFound(Boolean(data.logradouro?.length));

        setNeighborhood(data.bairro || "");
        setNeighborhoodFound(Boolean(data.bairro?.length));

        setCity(data.localidade);
        setState(data.uf);
        setAddressLoaded(true);
      })
      .catch((e) => {
        alerts.error("Não foi possível carregar as informações do endereço.");
      })
      .finally(() => setLoading(false));
  };

  const canContinue = () => {
    if (step === "identity") {
      return isNameValid(name) && isEmailValid(email) && isPhoneValid(phone);
    }

    return (
      street.length > 0 && streetNumber.length > 0 && neighborhood.length > 0 && city.length > 0 && state.length > 0
    );
  };

  React.useEffect(() => loadAddress(), [postCode]);

  React.useEffect(() => {
    if (!user) return;

    setName(user.name);
    setEmail(user.email);
  }, [user]);

  return (
    <Modal open onClose={onClose}>
      <ModalCard>
        <Title>Informações</Title>
        <Subtitle>Preencha os dados necessários para receber sua recompensa.</Subtitle>
        {step === "identity" && (
          <>
            <Legend>Dados de contato</Legend>
            <FieldContainer>
              <TextField
                label="Nome completo"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                label="E-mail"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
              />
            </FieldContainer>
            <FieldContainer>
              <MaskedTextField
                mask="(99) 99999-9999"
                label="Telefone"
                variant="outlined"
                value={phone}
                onAccept={(value: string) => setPhone(value)}
                fullWidth
              />
            </FieldContainer>
          </>
        )}
        {step === "address" && (
          <>
            <Legend>Endereço</Legend>
            <FieldContainer>
              <MaskedTextField
                label="CEP"
                mask="99999-999"
                variant="outlined"
                value={postCode}
                onAccept={(value: string) => setPostCode(value)}
                fullWidth
              />
            </FieldContainer>
            {addressLoaded && (
              <>
                <FieldContainer>
                  <TextField
                    variant="outlined"
                    label="Logradouro"
                    name="street"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    // error={!!errors.street}
                    // helperText={errors.street || ""}
                    disabled={streetFound}
                    fullWidth
                  />
                </FieldContainer>
                <FieldContainer>
                  <Box display="flex">
                    <Box marginRight="10px">
                      <TextField
                        variant="outlined"
                        label="Número"
                        name="number"
                        value={streetNumber}
                        onChange={(e) => setStreetNumber(e.target.value)}
                        // error={!!errors.streetNumber}
                        // helperText={errors.streetNumber || ""}
                        fullWidth
                      />
                    </Box>
                    <Box marginLeft="10px">
                      <TextField
                        variant="outlined"
                        label="Complemento"
                        name="complement"
                        value={complement}
                        onChange={(e) => setComplement(e.target.value)}
                        // error={!!errors.complement}
                        // helperText={errors.complement || ""}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    variant="outlined"
                    label="Bairro"
                    value={neighborhood}
                    onChange={(e) => setNeighborhood(e.target.value)}
                    // error={!!errors.neighborhood}
                    // helperText={errors.neighborhood || ""}
                    disabled={neighborhoodFound}
                    fullWidth
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    variant="outlined"
                    label="Cidade"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    // error={!!errors.city}
                    // helperText={errors.city || ""}
                    disabled
                    fullWidth
                  />
                </FieldContainer>
                <FieldContainer>
                  <TextField
                    variant="outlined"
                    label="Estado"
                    value={state}
                    // error={!!errors.state}
                    // helperText={errors.state || ""}
                    disabled
                    fullWidth
                  />
                </FieldContainer>
              </>
            )}
          </>
        )}
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={termsAccepted} onChange={(_, checked) => setTermsAccepted(checked)} />}
            label="Aceito o compartilhamento das informações acima com o organizador da vaquinha."
          />
        </FormGroup>
        <Box marginTop="20px">
          <Button
            variant="outlined"
            size="large"
            onClick={() => {
              if (step === "identity" && types.includes("physical-product")) {
                setStep("address");
                return;
              }

              onClose({
                name,
                email,
                phone,
                postCode,
                street,
                streetNumber,
                complement,
                neighborhood,
                city,
                state,
              });
            }}
            disabled={!canContinue() || !termsAccepted || loading}
            fullWidth
          >
            Continuar
          </Button>
        </Box>
      </ModalCard>
    </Modal>
  );
}
